export function useModal() {
  const isOpen = ref(false)
  const target = ref(null)

  const open = () => (isOpen.value = true)
  const close = () => (isOpen.value = false)

  onClickOutside(target, close)

  onBeforeUnmount(() => document.documentElement.classList.remove('page-is-frozen'))

  // this doesn't work for some reason, because of createSharedComposable?

  // useHead({
  //   bodyAttrs: {
  //     class: computed(() => {
  //       console.log('isOpen.value', isOpen.value)
  //       if (isOpen.value) return 'page-is-frozen'

  //       return ''
  //     }),
  //   },
  // })

  watch(isOpen, (value) => {
    if (value) return document.documentElement.classList.add('page-is-frozen')
    document.documentElement.classList.remove('page-is-frozen')
  })

  return {
    target,
    isOpen,
    open,
    close,
  }
}

export const useSearchModal = createSharedComposable(useModal)
export const useCartModal = createSharedComposable(useModal)
export const useLoginModal = createSharedComposable(useModal)
export const useNavigationMobileModal = createSharedComposable(useModal)
export const useAddressModal = createSharedComposable(useModal)
export const useToccoModuleModal = createSharedComposable(useModal)
export const useEmptyModal = createSharedComposable(useModal)
